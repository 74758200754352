<template>
  <div id="mArticle">
    <div class="head_section">
      <h2 id="dkBody" class="tit_section">공용결재선 관리</h2>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <!-- admin 타입 type_admin -->
        <caption class="ir_caption">
          공용결재선 계정 관리 표
        </caption>
        <colgroup>
          <col style="width: 160px" />
          <col />
          <col style="width: 160px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">결재선 명 <sup>*</sup></th>
            <td colspan="3">
              <Input ref="name" :value.sync="name" :maxLength="100" />
            </td>
          </tr>
          <tr>
            <th scope="row">결재선 <sup>*</sup></th>
            <td class="va_top">
              <!-- 2019-06-13 클래스 수정 -->
              <div class="box_search search_type3">
                <!-- 2019-06-13 클래스 수정 -->
                <comm-inp-search
                  :url="this.$apiPath.APRVL_ORGEMP"
                  :initValue="''"
                  method="get"
                  inputRef="empInfo"
                  :isDisplayResult="false"
                  :displayData="displaySearchResult"
                  @select="addProcessingApproval"
                />
                <button
                  type="button"
                  class="btn_search"
                  @click.prevent="searchOrganization('procApproval')"
                >
                  <span class="ico_account ico_people">조직도 검색하기</span>
                </button>
              </div>
              <!--<div class="group_people" ref="procDeptApprLine">--><!-- 인풋과 함께 우측에 정렬될 때 : type_side 클래스 추가 -->
              <draggable
                class="group_people"
                ghost-class="drag_on"
                :list="procDeptApprLine"
                @start="drag = true"
                @end="drag = false"
              >
                <comm-item-emp
                  v-for="emp in procDeptApprLine"
                  :key="emp.empNo"
                  :empName="emp.empName"
                  :accountId="emp.accountId"
                  :isTypeMe="emp.typeMe"
                  @remove-employee="removeApproval(emp)"
                />
              </draggable>
              <!--</div>-->
            </td>
            <th scope="row">참조자</th>
            <td class="va_top">
              <!-- 2019-06-13 클래스 수정 -->
              <div class="box_search search_type3">
                <!-- 2019-06-13 클래스 수정 -->
                <comm-inp-search
                  :url="this.$apiPath.APRVL_ORGEMP"
                  :initValue="''"
                  method="get"
                  inputRef="empInfo"
                  :isDisplayResult="false"
                  :displayData="displaySearchResult"
                  @select="addReferenceApproval"
                />
                <button
                  type="button"
                  class="btn_search"
                  @click.prevent="searchOrganization('ccApproval')"
                >
                  <span class="ico_account ico_people">조직도 검색하기</span>
                </button>
              </div>
              <!--<div class="group_people" ref="referenceApprovalLine">--><!-- 인풋과 함께 우측에 정렬될 때 : type_side 클래스 추가 -->
              <draggable
                class="group_people"
                ghost-class="drag_on"
                :list="referenceApprovalLine"
                @start="drag = true"
                @end="drag = false"
              >
                <comm-item-emp
                  v-for="emp in referenceApprovalLine"
                  :key="emp.empNo"
                  :empName="emp.empName"
                  :accountId="emp.accountId"
                  :isTypeMe="emp.typeMe"
                  @remove-employee="removeApproval(emp)"
                />
              </draggable>
              <!--</div>-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="wrap_btn type_bottom">
      <div class="pos_left">
        <button type="button" class="btn_large btn_fourthly" @click.prevent="goToList()">
          취소
        </button>
      </div>
      <div class="pos_right">
        <button
          v-if="pubApprLineId != undefined"
          type="button"
          class="btn_large btn_secondary"
          @click.prevent="checkPublicAprrAndAccountAppr"
        >
          삭제
        </button>
        <button type="button" class="btn_large btn_primary" @click.prevent="checkTempValidation">
          저장
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ApiService from "@/services/ApiService";

import CommOpt from "@/_approval/components/common/CommOpt";
import CommInpSearch from "@/_approval/components/common/CommInpSearch";
import CommItemEmp from "@/_approval/components/common/CommItemEmp";
import Input from "@/_approval/components/common/input/Input";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { default as CommFlatpickrMixin } from "@/_approval/mixins/common/commFlatpickr.js";
import { default as CommMomentMixin } from "@/_approval/mixins/common/commMoment.js";
import { default as CommDraggableMixin } from "@/_approval/mixins/common/commDraggable.js";

export default {
  name: "PublicApprovalLineEdit",
  components: {
    CommOpt,
    CommInpSearch,
    CommItemEmp,
    Input,
  },
  mixins: [CommLayerMixin, CommFlatpickrMixin, CommMomentMixin, CommDraggableMixin],
  data() {
    return {
      publicApprovalLineList: [],
      name: "",
      procDeptApprLine: [],
      referenceApprovalLine: [],
      pubApprLineId: "",
      clickedApproval: {},
    };
  },
  computed: {},
  watch: {
    procDeptApprLine() {
      return this.procDeptApprLine.map(function (item, idx) {
        item.apprOrder = idx + 1;
      });
    },
    referenceApprovalLine() {
      return this.referenceApprovalLine.map(function (item, idx) {
        item.apprOrder = idx + 1;
      });
    },
  },
  created() {
    this.pubApprLineId = this.$route.query.pubApprLineId;

    if (this.pubApprLineId != undefined) {
      this.selectPublicApplovalLine();
    }
  },
  methods: {
    displaySearchResult(dataList) {
      // 임직원 표기 방식
      if (!dataList.data) return;

      dataList.data.forEach((value) => {
        value.display = value.accountId + "(" + value.empName + ")";
      });
    },
    addProcessingApproval(emp) {
      // 결재선 추가
      this.procDeptApprLine.push({
        empNo: emp.empNo,
        empName: emp.empName,
        accountId: emp.accountId,
        aprvlLineType: "A",
      });
    },
    addReferenceApproval(emp) {
      // 참조자 추가
      this.referenceApprovalLine.push({
        empNo: emp.empNo,
        empName: emp.empName,
        accountId: emp.accountId,
        aprvlLineType: "R",
      });
    },
    searchOrganization(searchType) {
      // 조직도 레이어
      this.searchType = searchType;
      let employeeList = [];
      switch (searchType) {
        case "procApproval":
          employeeList = employeeList.concat(this.procDeptApprLine);
          break;
        case "ccApproval":
          employeeList = employeeList.concat(this.referenceApprovalLine);
          break;
      }
      let layer = {};
      layer.type = "popup";
      layer.class = "inner_structure_layer";
      layer.header = "조직도";
      layer.componentFile = "common/CommPopOrganization.vue";
      layer.props = {
        orgType: 2, // 1:single select, 2: multi select
        caution: "",
        employeeList: employeeList,
      };
      this._showLayer(layer, this.attachEmployee);
    },
    attachEmployee(employeeList) {
      // 조직도에서 임직원 적용
      let callback;
      switch (this.searchType) {
        case "procApproval":
          callback = this.addProcessingApproval;
          this.procDeptApprLine.splice(0);
          break;
        case "ccApproval":
          callback = this.addReferenceApproval;
          this.referenceApprovalLine.splice(0);
          break;
      }
      employeeList.forEach((emp) => {
        callback(emp);
      });
    },
    editPublicApplovalLine() {
      if (this.pubApprLineId != undefined) {
        //수정
        this.updatePublicApplovalLine();
      } else {
        //저장
        this.savePublicApplovalLine();
      }
    },
    getObjToSend() {
      let cmmnAprvlLineTargetList = [];

      this.procDeptApprLine.map(function (item, idx) {
        const emp = {};
        emp.empNo = item.empNo;
        emp.empName = item.empName;
        emp.accountId = item.accountId;
        emp.aprvlLineType = item.aprvlLineType;
        emp.aprvlOrder = idx + 1;
        cmmnAprvlLineTargetList.push(emp);
      });

      let size = cmmnAprvlLineTargetList.length;

      size++;
      this.referenceApprovalLine.map(function (item, idx) {
        const emp = {};
        emp.empNo = item.empNo;
        emp.empName = item.empName;
        emp.accountId = item.accountId;
        emp.aprvlLineType = item.aprvlLineType;
        emp.aprvlOrder = size + idx;
        cmmnAprvlLineTargetList.push(emp);
      });

      let obj = {};
      if (this.pubApprLineId != undefined) {
        //수정
        obj = {
          cmmnAprvlLineNo: this.pubApprLineId,
          name: this.name,
          cmmnAprvlLineTargetList: cmmnAprvlLineTargetList,
        };
      } else {
        obj = {
          name: this.name,
          cmmnAprvlLineTargetList: cmmnAprvlLineTargetList,
        };
      }

      return obj;
    },
    async updatePublicApplovalLine() {
      // 공용결재선 수정
      const path = `${this.$apiPath.APRVL_PBLC_APRVL_LINE}`;
      const obj = this.getObjToSend();
      const result = await ApiService.shared.putData(path, obj);

      if (result.code !== "200") {
        this._showLayer({ type: "alert", content: result.text });
        return;
      }

      this.goToList();
    },
    async savePublicApplovalLine() {
      // 공용결재선 저장
      const path = `${this.$apiPath.APRVL_PBLC_APRVL_LINE}`;
      const obj = this.getObjToSend();

      const result = await ApiService.shared.postData(path, obj);

      if (result.code !== "200") {
        this._showLayer({ type: "alert", content: result.text });
        // this.$emit( 'alert', result.text );
        return;
      }

      this.goToList();
    },
    editConfirmLayer() {
      // 저장 컨펌레이어
      const layer = {};
      layer.type = "confirm";
      layer.header = "공용결재선 관리"; // 레이어 타이틀
      layer.content = "저장 하시겠습니까?"; // 레이어 내용
      this._showLayer(layer, this.editPublicApplovalLine);
    },
    async selectPublicApplovalLine() {
      const path = `${this.$apiPath.APRVL_PBLC_APRVL_LINE}/` + this.pubApprLineId;
      const result = await ApiService.shared.getData(path);

      if (!result.data) return;
      //      const obj = this;
      let list = result.data;
      this.name = list.name;
      let procDeptApprLine = [];
      let referenceApprovalLine = [];
      this.procDeptApprLine = [];

      this.referenceApprovalLine = [];

      list.cmmnAprvlLineTargetList.map((item, idx) => {
        if (item.aprvlLineType === "A") {
          //결재자
          const emp = {};
          emp.empNo = item.empNo;
          emp.empName = item.empName;
          emp.accountId = item.accountId;
          emp.aprvlLineType = item.aprvlLineType;
          procDeptApprLine.push(emp);

          this.procDeptApprLine = procDeptApprLine;
        } else {
          // 참조자
          const emp = {};
          emp.empNo = item.empNo;
          emp.empName = item.empName;
          emp.accountId = item.accountId;
          emp.aprvlLineType = item.aprvlLineType;
          referenceApprovalLine.push(emp);
          this.referenceApprovalLine = referenceApprovalLine;
        }
      });
      this.publicApprovalLineList = result.data;
    },
    // selectPublicApplovalLine () { // 공용결재선 조회
    // axios.post('/api/admin/selectPublicApprovalLine',
    //   { pubApprLineId: this.pubApprLineId
    //   })
    //   .then((res) => {
    //     const obj = this
    //     let list = res.data
    //     let procDeptApprLine = []
    //     let referenceApprovalLine = []
    //     this.procDeptApprLine = []
    //     this.referenceApprovalLine = []
    //     return list.map(function (item, idx) {
    //       if (idx === 0) obj.apprLineName = item.apprLineName
    //       if (item.apprLineFlag === '3') {
    //         const emp = {}
    //         emp.empNo = item.empNo
    //         emp.empName = item.empName
    //         emp.accountId = item.accountId
    //         emp.aprvlLineType = 'proc'
    //         procDeptApprLine.push(emp)
    //         obj.procDeptApprLine = procDeptApprLine
    //       } else {
    //         const emp = {}
    //         emp.empNo = item.empNo
    //         emp.empName = item.empName
    //         emp.accountId = item.accountId
    //         emp.aprvlLineType = 'cc'
    //         referenceApprovalLine.push(emp)
    //         obj.referenceApprovalLine = referenceApprovalLine
    //       }
    //     })
    //   })
    //   .catch(function (error) {
    //     console.log(error)
    //   })
    // },
    async checkPublicAprrAndAccountAppr() {
      // 삭제 전 해당 공용결재선과 연결되어있는 계정이 있는지 확인 (연결되어있는 계정이있으면 삭제 X )
      const path = `${this.$apiPath.APRVL_LINE_ACCONT}/check/` + this.pubApprLineId;
      const result = await ApiService.shared.getData(path);

      if (result.code != 200) return;

      if (result.text === "true") {
        let header = "공용결재선 삭제";
        let content =
          "[" +
          this.name +
          "] 해당 결재선을 사용하고 있습니다.<br><br> 결재선 관리에서 변경하고 삭제해주세요.";
        // 공통 alert 레이어 출력
        const layer = {};
        layer.header = header;
        layer.content = content;
        layer.type = "alert";
        this._showLayer(layer, this.moveFocus(focus));
      } else {
        const layer = {};
        layer.content = "정말 삭제하시겠습니까?";
        layer.type = "confirm";
        this._showLayer(layer, this.deletePublicAprvl);
      }
    },
    // deletePublic () { // 공용결재선 삭제
    //   axios.post('/api/admin/deletePublicApprovalLine',
    //     { pubApprLineId: this.pubApprLineId
    //     })
    //     .then((res) => {
    //       console.log(res.data)
    //       this.$router.push('/admin/publicApprovalLine')
    //     })
    //     .catch(function (error) {
    //       console.log(error)
    //     })
    // },
    async deletePublicAprvl() {
      const path = `${this.$apiPath.APRVL_PBLC_APRVL_LINE}/` + this.pubApprLineId;
      const result = await ApiService.shared.delete(path);

      if (result.code !== "200") {
        this._showLayer({ type: "alert", content: result.text });
        return;
      }

      this.goToList();
    },
    checkTempValidation() {
      // 저장 전 유효성 체크
      let focus = "";
      let result = true;
      let header = "";
      let content = "";
      if (!this.name) {
        header = "결재선명 미입력";
        content = "결재선명에 내용이 없습니다. 입력후 다시 저장 바랍니다.";
        result = false;
        focus = "name";
      } else if (this.procDeptApprLine.length <= 0) {
        header = "결재선 미등록";
        content = "결재선에 등록된 결재자가 없습니다.";
        result = false;
      }
      if (!result) {
        // 공통 alert 레이어 출력
        const layer = {};
        layer.header = header;
        layer.content = content;
        layer.type = "alert";
        this._showLayer(layer, this.moveFocus(focus));
        return false;
      } else {
        return this.editConfirmLayer();
      }
    },
    removeApproval(removeEmp) {
      // 결재선 참조선 삭제
      // const obj = this;
      switch (removeEmp.aprvlLineType) {
        case "A":
          this.procDeptApprLine.forEach((value, index) => {
            if (value.empNo === removeEmp.empNo) {
              this.procDeptApprLine.splice(index, 1);
            }
          });
          break;
        case "R":
          this.referenceApprovalLine.forEach((value, index) => {
            if (value.empNo === removeEmp.empNo) {
              this.referenceApprovalLine.splice(index, 1);
            }
          });
          break;
      }
    },
    moveFocus(focus) {
      switch (focus) {
        case "name":
          this.$refs.name.$el.focus();
          break;
      }
    },
    goToList() {
      const path = this.$paymentRouterPath.PAYMENT_SYSTEM_PUBLIC_LINE_LIST;
      this.$router.push({ path: path });
    },
  },
};
</script>
